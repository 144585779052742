import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'antd/dist/antd.css';
import "./index.css"
import {loadSdkAsynchronously} from "./loadFb";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

const sentryDSN = process.env["REACT_APP_SENTRY_DSN"] || "";
const sentryEnv = process.env.NODE_ENV;
Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: sentryEnv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

loadSdkAsynchronously();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
