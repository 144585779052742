import React, {useEffect, useState} from "react";
import {getTransactions, PayersWithTransactions} from "../../api/transactions";
import PayersTransactions from "./PayersTransactions";
import {Spin} from "antd";
import {useStoreContext} from "../../store/store";

function PayersTransactionsWrapper() {
    const [payersTransactions, setPayersTransactions] = useState<PayersWithTransactions>();
    const [loading, setLoading] = useState(false);
    const {dispatch} = useStoreContext()
    useEffect(() => {
        setLoading(true);
        getTransactions()
            .then(transactions => setPayersTransactions(transactions))
            .finally(() => {
                setLoading(false);
            })
    }, [dispatch]);

    return <>
        <Spin size="large" spinning={loading}>
            {payersTransactions !== undefined && <PayersTransactions transactions={payersTransactions}/>}
        </Spin>
    </>
}

export default PayersTransactionsWrapper;
