export const loadSdkAsynchronously = () => {
    ((d, s, id) => {
        const element = d.getElementsByTagName(s)[0];
        const fjs = element;
        let js = element;
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = `https://connect.facebook.net/pl_PL/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
}
