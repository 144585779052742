import axios from "axios";
import {API_URL} from "../constants";
import {message} from "antd";
import {UserWithToken} from "../auth/User";

export const unsecuredAxiosInstance = axios.create({
    baseURL: API_URL,
});

unsecuredAxiosInstance.interceptors.response.use(response => response, (error) => {
    const {response} = error;
    if (!response) {
        console.log(error);
        return;
    }

    if (response.status === 401) {
        message.info("Proszę się zalogować")
        // authService.logout()
    } else if (response.status === 403) {
        message.error("Twoje konto jest nieaktywne, poproś administratora o zgodę", 10)
        // authService.logout()
    }

    return Promise.reject(error)
})

export const signIn = (accessToken: string): Promise<UserWithToken> => {
    return unsecuredAxiosInstance.post<UserWithToken>("/login", {accessToken}).then(res => {
        return res.data;
    })
}

