export interface Action {
    type: string,
    payload?: any
}

type PayloadCreator<P = any> = (...args: P[]) => any

type ActionCreatorWithParams<PC extends PayloadCreator> = _ActionCreatorWithParams<Parameters<PC>, PC>

interface _ActionCreatorWithParams<Args extends unknown[], PC> {
    type: string
    (...payload: Args): PC
    toString(): string
}

export function createAction<PC extends PayloadCreator>(type: string, payloadCreator?: PC): ActionCreatorWithParams<PC> {
    const fun = (...args: any[]): any => {
        if (payloadCreator) {
            const payloadCreated = payloadCreator(...args)
            return {
                type,
                payload: payloadCreated
            }
        }
        return {
            type,
            payload: args[0]
        }
    }
    fun.type = type
    fun.toString = () => `${type}`

    return fun
}
