import React from "react";
import {PayersWithTransactions, Transaction} from "../../api/transactions";
import {Table, Tabs} from "antd";
import {formatMoney, Money} from "../../utils/money";

interface Props {
    transactions: PayersWithTransactions
}

const columns = [
    {
        title: "Data",
        dataIndex: "date",
        key: "date"
    },
    {
        title: "Opis",
        dataIndex: "title",
        key: "title"
    },
    {
        title: "Kwota",
        dataIndex: "amount",
        key: "amount",
        render: (amount: Money) => formatMoney(amount)
    }
];

function mapTransactions(transactions: Transaction[]) {
    return transactions.map(t => {
        return Object.assign({}, t, {key: t.id});
    })
}

function PayersTransactions({transactions}: Props) {
    return (
        <Tabs>
            {transactions.payersWithTransactions.map(pt => (
                <Tabs.TabPane key={pt.payer} tab={pt.payer}>
                    <Table columns={columns} dataSource={mapTransactions(pt.transactions)} />
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
}

export default PayersTransactions;
