import React from "react";
import {Button, message, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {API_URL} from "../../constants";
import {useStoreContext} from "../../store/store";
import {lastImportAwaits, transactionsAwait} from "../../store/actions";
import {authService} from "../../auth/authService";

function UploadTransactions() {
    const {dispatch} = useStoreContext();
    const token = authService.getToken() || "";
    const props: UploadProps = {
        name: "file",
        action: `${API_URL}/transactions`,
        showUploadList: false,
        headers: {
            "Authorization": `Bearer ${token}`
        },
        beforeUpload: file => {
            if (file.type !== "text/csv") {
                message.error(`${file.name} nie jest popranym plikiem CSV!`)
            }
            return file.type === "text/csv" ? true : Upload.LIST_IGNORE
        },
        onChange: info => {
            if (info.file.status === "done") {
                message.success("Import zakończony pomyślnie")
                dispatch(lastImportAwaits(true));
                dispatch(transactionsAwait(true));
            } else if (info.file.status === "error") {
                message.error("Błąd podczas importowania pliku")
            }
        }
    }

    if (localStorage.getItem("admin") !== "1") {
        return null;
    }

    return (
        <Upload {...props}>
            <Button icon={<UploadOutlined />}>Importuj transakcje</Button>
        </Upload>
    );
}

export default UploadTransactions;
