import {createContext, useContext, useEffect, useState} from "react";
import {signIn} from "../api/auth";
import {User} from "./User";
import {authService} from "./authService";
import {FB_CLIENT_ID} from "../constants";

type Store = {
    user: User|null,
    login: (accessToken: string) => Promise<User>,
    logout: () => void
}

const authContext = createContext<Store>({
    user: null,
    login: () => {
        return Promise.resolve({} as User);
    },
    logout: () => {}
});

export const useAuth = () => {
    return useContext(authContext)
}

type ProvideAuthProps = {
    children: any
}

export const ProvideAuth = ({children}: ProvideAuthProps) => {
    const auth = useProvideAuth()
    const [checkingLoginStatus, setCheckingLoginStatus] = useState(true);
    const [fbLoaded, setFbLoaded] = useState(false);

    useEffect(() => {
        // @ts-ignore
        window.fbAsyncInit = (() => {
            // eslint-disable-next-line no-undef
            FB.init({
                appId: FB_CLIENT_ID,
                cookie: true,
                xfbml: true,
                version: 'v11.0'
            });
            setFbLoaded(true);
            console.log("FB loaded");
        });
    })

    useEffect(() => {
        if (!fbLoaded && !auth.user) {
            return;
        }
        console.log("Getting login status")
        getLoginStatus().then((response: any) => auth.login(response.accessToken))
            .finally(() => setCheckingLoginStatus(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fbLoaded]);

    return (
        <authContext.Provider value={auth}>
            {!checkingLoginStatus && children}
        </authContext.Provider>
    )
}

const getLoginStatus = () => {
    return new Promise((resolve, reject) => {
        FB.getLoginStatus((status) => {
            if (status.status === "connected") {
                resolve(status.authResponse);
            } else {
                reject()
            }
        });
    });
}

const useProvideAuth = () => {
    const [user, setUser] = useState<User|null>(null);
    const login = (accessToken: string) => {
        return signIn(accessToken).then(userWithToken => {
            setUser(userWithToken.userProfile)
            authService.setUser(userWithToken)
            return userWithToken.userProfile;
        });
    }
    const logout = () => {
        setUser(null)
    };

    return {
        user,
        login,
        logout
    }
}


