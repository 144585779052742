import React, {createContext, useContext, useReducer} from "react";
import {appReducer, AppState, initialState} from "./reducer";

interface Store {
    state: AppState,
    dispatch: React.Dispatch<any>
}

const store = createContext<Store>({
    state: initialState,
    dispatch: () => null
});
const { Provider } = store;

interface Props {
    children: any
}

const StateProvider = ({children}: Props) => {
    const [state, dispatch] = useReducer(appReducer, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>
}

const useStoreContext = () => {
    const context = useContext<Store>(store)
    if (context === null) {
        throw new Error("Store context is not initialized!")
    }

    return context
}

export { useStoreContext, StateProvider }
