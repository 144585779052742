import React from "react";
import {useAuth} from "../../auth/authContext";
import {Redirect, Route} from "react-router-dom";

interface Props {
    children: any,
    path: any,
    exact: any
}

function PrivateRoute({children, ...rest}: Props) {
    const auth = useAuth();
    return (
        <Route {...rest} render={({location}) =>
            auth.user ? (
                children
            ) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: location}
                }} />
            )
        } />
    );
}

export default PrivateRoute;
