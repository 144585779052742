import {User, UserWithToken} from "./User";

type userObjType = {
    user: User|null,
    token: string|null
}
const userObj: userObjType = {
    user: null,
    token: null
};

const logout = () => {
    FB.logout()
}

const setUser = (user: UserWithToken) => {
    userObj.user = user.userProfile;
    userObj.token = user.token;
}

export const authService = {
    getUser: () => userObj.user,
    getToken: () => userObj.token,
    setUser,
    logout
}
