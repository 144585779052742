import { Result } from "antd";
import React, {ReactNode, useEffect} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {healthCheck} from "../../api/healthCheck";
import {useStoreContext} from "../../store/store";
import {healthCheckLoading, setHealthCheckState} from "../../store/actions";

interface Props {
    children: ReactNode
}

function AppLoader({children}: Props) {
    const {state, dispatch} = useStoreContext();

    useEffect(() => {
        if (state.healthCheckLoading === true || state.healthCheckState !== undefined) {
            return;
        }
        dispatch(healthCheckLoading(true))
        healthCheck()
            .then(() => {
                dispatch(setHealthCheckState(true))
            })
            .catch(() => {
                dispatch(setHealthCheckState(false))
            })
            .finally(() => {
                dispatch(healthCheckLoading(false));
            });
    }, [dispatch, state]);

    if (state.healthCheckState === false) {
        return (
            <Result status="error"
                    title="Wystąpił błąd przy ładowaniu aplikacji"
                    subTitle="Ja już pewnie wiem, co się odwaliło... ale jak nie wiem to daj mi znać"
            />
        )
    }

    if (state.healthCheckLoading === true || state.healthCheckLoading === undefined) {
        return (<Result
            icon={<SyncOutlined spin />}
            title="Ładowanie..."
            subTitle="Oczekiwanie na aplikację po dłuższej nieaktywności..."
        />)
    }

    return <>{children}</>;
}

export default AppLoader;
