import {axiosInstance} from "./client";
import {Money} from "../utils/money";
import {DateTime} from "luxon";

export type PayersWithTransactions = {
    payersWithTransactions: PayerWithTransactions[]
}

export type PayerWithTransactions = {
    payer: string,
    transactions: Transaction[]
}

export type Transaction = {
    id: string,
    title: string,
    amount: Money,
    date: Date,
    balance: Money
}

export type TransactionsImportInfo = {
    importId: string,
    importDate: DateTime,
    transactionsCount: number,
    currentBalance: Money
}

export const getImport = (): Promise<TransactionsImportInfo> => {
    return axiosInstance.get<TransactionsImportInfo>("/import", {
        transformResponse: [(data: any) => {
            const tempData = JSON.parse(data)
            tempData.importDate = DateTime.fromISO(tempData.importDate)
            return tempData;
        }]
    }).then(res => {
        return res.data;
    });
};

export const getTransactions = (): Promise<PayersWithTransactions> => {
    return axiosInstance.get<PayersWithTransactions>("/transactions").then(res => {
        return res.data
    });
};
