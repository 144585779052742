import React, {useEffect, useState} from "react";
import {getImport, TransactionsImportInfo} from "../../api/transactions";
import CurrentStatus from "./CurrentStatus";
import {PageHeader, Spin} from "antd";
import UploadTransactions from "../UploadTransactions/UploadTransactions";
import {useStoreContext} from "../../store/store";

function CurrentStatusWrapper() {
    const [lastImport, setLastImport] = useState<TransactionsImportInfo>();
    const [loading, setLoading] = useState(false);
    const {dispatch} = useStoreContext()
    useEffect(() => {
        setLoading(true);
        getImport()
            .then(i => setLastImport(i))
            .finally(() => {
                setLoading(false);
            })
    }, [dispatch]);

    return <Spin size="large" spinning={loading}>
        <PageHeader title="Fundusz remontowy" extra={[<UploadTransactions key={1} />]}>
            {lastImport !== undefined && <CurrentStatus currentBalance={lastImport.currentBalance} lastImportDate={lastImport.importDate} />}
        </PageHeader>
    </Spin>
}

export default CurrentStatusWrapper;
