import React from "react";
import CurrentStatusWrapper from "../components/CurrentStatus/CurrentStatusWrapper";
import {Divider, Typography} from "antd";
import PayersTransactionsWrapper from "../components/PayersTransactions/PayersTransactionsWrapper";

function TransactionsPage() {
    return (
        <>
            <CurrentStatusWrapper />
            <Divider />
            <Typography.Title level={2}>Ostatnie transakcje</Typography.Title>
            <PayersTransactionsWrapper />
        </>
    );
}

export default TransactionsPage;
