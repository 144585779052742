import {Action} from "./actionHelpers";
import {healthCheckLoading, lastImportAwaits, setHealthCheckState, transactionsAwait} from "./actions";

export interface AppState {
    healthCheckState?: boolean
    healthCheckLoading?: boolean,
    lastImportRequestAwaiting: boolean
    transactionsRequestAwaiting: boolean
}

export const initialState: AppState = {
    lastImportRequestAwaiting: true,
    transactionsRequestAwaiting: true
}

export const appReducer = (state: AppState, action: Action) => {
    switch (action.type) {
        case healthCheckLoading.type:
            return {...state, healthCheckLoading: action.payload.loadingStatus};
        case setHealthCheckState.type:
            return {...state, healthCheckState: action.payload.status};
        case lastImportAwaits.type:
            return {...state, lastImportRequestAwaiting: action.payload.state};
        case transactionsAwait.type:
            return {...state, transactionsRequestAwaiting: action.payload.state}
        default:
            return state
    }

}
