import React from 'react';
import {Layout, Menu, Typography} from "antd";
import AppLoader from './components/AppLoader/AppLoader';
import {StateProvider} from "./store/store";
import {Router, Route, Switch, Redirect, Link} from "react-router-dom";
import TransactionsPage from "./pages/TransactionsPage";
import LoginPage from "./pages/LoginPage";
import {ProvideAuth} from "./auth/authContext";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import {history} from "./utils/history";
import ShiftsPage from "./pages/ShiftsPage";

function App() {
    return (
        <StateProvider>
            <ProvideAuth>
                <Router history={history}>
                    <Layout>
                        <Layout.Header>
                            <Typography.Text strong className="title">Łuczanowska 8</Typography.Text>
                            <Menu theme="dark" mode="horizontal">
                                <Menu.Item key="1">
                                    <Link to="/transactions">Transakcje</Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Link to="/shifts">Dyżury</Link>
                                </Menu.Item>
                            </Menu>
                        </Layout.Header>
                        <Layout.Content className="content">
                            <AppLoader>
                                <Switch>
                                    <PrivateRoute path="/transactions" exact={true}>
                                        <TransactionsPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/shifts" exact={true}>
                                        <ShiftsPage/>
                                    </PrivateRoute>
                                    <Route path="/login">
                                        <LoginPage/>
                                    </Route>
                                    <Route path="/" exact={true}>
                                        <Redirect to="/transactions"/>
                                    </Route>
                                </Switch>
                            </AppLoader>
                        </Layout.Content>
                    </Layout>
                </Router>
            </ProvideAuth>
        </StateProvider>
    );
}

export default App;
