import axios, {AxiosRequestConfig} from "axios";
import {API_URL} from "../constants";
import {authService} from "../auth/authService";

export const axiosInstance = axios.create({
    baseURL: API_URL,
});

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    let token = authService.getToken();
    if (token !== null) {
        request.headers.common.Authorization = `Bearer ${token}`
    }
    return request
});
