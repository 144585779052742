import React from "react";
import {formatMoney, Money} from "../../utils/money";
import {Descriptions, Statistic} from "antd";
import {DateTime} from "luxon";

interface Props {
    currentBalance: Money,
    lastImportDate: DateTime
}

function CurrentStatus({currentBalance, lastImportDate}: Props) {
    return (
        <>
            <Statistic title="Obecny stan konta" value={formatMoney(currentBalance)} />
            <Descriptions>
                <small>Ostatni import danych: {lastImportDate.toLocaleString(DateTime.DATETIME_MED)}</small>
            </Descriptions>

        </>
    );
}

export default CurrentStatus;
