import React from "react";
import ReactFacebookLogin, {ReactFacebookLoginInfo} from "react-facebook-login";
import { useHistory, useLocation } from "react-router-dom";
import {useAuth} from "../auth/authContext";
import {FB_CLIENT_ID} from "../constants";
import "./LoginPage.css";
import {FacebookOutlined} from "@ant-design/icons";

function LoginPage() {
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation()
    const cb = (res: ReactFacebookLoginInfo) => {
        return auth.login(res.accessToken).then(() => {
            const {from}: any = location.state || { from: { pathname: "/" } }
            history.replace(from);
        });
    }

    if (auth.user !== null) {
        const {from}: any = location.state || { from: { pathname: "/" } }
        history.replace(from)
    }

    return (
        <div className="centered">
            <ReactFacebookLogin
                appId={FB_CLIENT_ID}
                callback={cb}
                fields="name,email,picture"
                autoLoad={true}
                textButton={" Zaloguj się"}
                icon={<FacebookOutlined />}
            />
        </div>
    );
}

export default LoginPage;
